<ion-app>
  <ion-split-pane contentId="main" when="md" [disabled]="shouldHideSplitPane() || isPageWithoutMenu()">
    <ion-menu id="theMenu" contentId="main" [class.miniMode]="isMiniMode()">
      <ion-header class="ion-no-border">
        <ion-toolbar color="ideaToolbar">
          <ion-avatar class="profileAvatar tappable" [title]="t._('MENU.PROFILE_I')" (click)="openProfile()">
            <ion-img [src]="app.getUserImageURL(app.user)" (ionError)="app.fallbackUserAvatar($event.target)"></ion-img>
          </ion-avatar>
          <div class="underAvatar ion-text-wrap" *ngIf="!isMiniMode()">
            <ion-text class="tappable" [title]="t._('MENU.PROFILE_I')" (click)="openProfile()">
              {{ app.user?.name }}
            </ion-text>
          </div>
        </ion-toolbar>
      </ion-header>
      <ion-content color="ideaToolbar">
        <ion-list lines="none">
          <div *ngFor="let p of menuPages">
            <ion-menu-toggle auto-hide="false" *ngIf="isPageVisible(p)">
              <ion-item-divider *ngIf="p.hasHeader">
                <ion-label [color]="app.isInDarkMode() ? 'medium' : 'light'">
                  <span *ngIf="!isMiniMode()">{{ p.hasHeader | translate }}</span>
                </ion-label>
              </ion-item-divider>
              <ion-button
                expand="full"
                fill="clear"
                *ngIf="isMiniMode()"
                [color]="app.isInDarkMode() ? 'primary' : 'light'"
                [title]="t._(p.title.concat('_I'))"
                (click)="p.method ? runMenuMethod(p.method) : navigateToPath(p.navigationPath)"
              >
                <ion-icon slot="icon-only" [icon]="p.icon"></ion-icon>
              </ion-button>
              <ion-item
                *ngIf="!isMiniMode()"
                [button]="true"
                [title]="t._(p.title.concat('_I'))"
                (click)="p.method ? runMenuMethod(p.method) : navigateToPath(p.navigationPath)"
              >
                <ion-icon slot="start" [color]="app.isInDarkMode() ? 'primary' : 'light'" [icon]="p.icon"></ion-icon>
                <ion-label [color]="app.isInDarkMode() ? 'dark' : 'light'">{{ p.title | translate }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
        </ion-list>
      </ion-content>
      <ion-footer class="ion-no-border" *ngIf="!app.isMobile()">
        <ion-toolbar color="ideaToolbar">
          <ion-button
            size="small"
            fill="clear"
            expand="full"
            [color]="app.isInMobileMode() ? 'dark' : 'light'"
            (click)="toggleMiniMode()"
          >
            <ion-icon slot="icon-only" [icon]="isMiniMode() ? 'chevron-forward' : 'chevron-back'"></ion-icon>
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
