import { Resource, epochISODateString, toISODate } from 'idea-toolbox';

export class Widget extends Resource {
  /**
   * An ID to safely identify the widget in the user's data context.
   */
  id: number;
  /**
   * A title for the widget.
   */
  name: string;
  /**
   * A description for the widget.
   */
  description: string;
  /**
   * The type of widget.
   */
  type: WidgetTypes;
  /**
   * The data source for the widget.
   */
  source: WidgetDataSourceAttributes;
  /**
   * The configuration to filter the source data to display in the widget.
   */
  filters: Record<string, string[]>;
  /**
   * The type of temporal interval.
   */
  intervalType: WidgetIntervalTypes;
  /**
   * The start of the time interval to consider for data from the source.
   * Set with `WidgetIntervalTypes.FIXED`.
   */
  since?: epochISODateString;
  /**
   * The end of time interval to consider for data from the source.
   * Set with `WidgetIntervalTypes.FIXED`.
   */
  to?: epochISODateString;
  /**
   * The number of days to consider as starting interval, compared to today, for data from the source.
   * Set with `WidgetIntervalTypes.RELATIVE`.
   */
  relativeSince?: number;
  /**
   * The number of days to consider as ending interval, compared to today, for data from the source.
   * Set with `WidgetIntervalTypes.RELATIVE`.
   */
  relativeTo?: number;
  /**
   * The sorting mechanism for the data from the source.
   * Only valid for type `SORTED_LIST`.
   */
  sort: WidgetDataSortings;
  /**
   * The attribute used for grouping values.
   */
  groupByAttribute: string;
  /**
   * The attribute used for dividing values for widgets of type `SORTED_LIST` and source with origin of type `POINT`.
   */
  divideByAttribute: string;
  /**
   * The attribute used for grouping values.
   * Only valid for type `BAR` or `AREA`.
   */
  granularity: WidgetDataGranularities;

  /**
   * Get the origin of a data source.
   */
  static getSourceOrigin(source: WidgetDataSourceAttributes | string): WidgetDataSourceOrigin {
    return source?.split(':')[0] as WidgetDataSourceOrigin;
  }
  /**
   * Get the type of origin of a data source.
   */
  static getSourceOriginType(source: WidgetDataSourceAttributes | string): WidgetDataSourceOriginTypes {
    return source?.split(':')[2] as WidgetDataSourceOriginTypes;
  }

  load(x: any): void {
    super.load(x);
    this.id = this.clean(x.id, Number);
    this.name = this.clean(x.name, String);
    this.description = this.clean(x.description, String);
    this.type = this.clean(x.type, String);
    this.source = this.clean(x.source, String);
    this.filters = {};
    if (x.filters) for (const filter in x.filters) this.filters[filter] = this.cleanArray(x.filters[filter], String);
    this.intervalType = this.clean(x.intervalType, String, WidgetIntervalTypes.FIXED);
    if (this.intervalType === WidgetIntervalTypes.FIXED) {
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      this.since = this.clean(x.since, toISODate, toISODate(oneYearAgo));
      this.to = this.clean(x.to, toISODate, toISODate(new Date()));
    } else if (this.intervalType === WidgetIntervalTypes.RELATIVE) {
      this.relativeSince = this.clean(x.relativeSince, Number, -365);
      this.relativeTo = this.clean(x.relativeTo, Number, 0);
    }
    this.sort = this.clean(x.sort, String, WidgetDataSortings.NONE);
    this.groupByAttribute = this.clean(x.groupByAttribute, String);
    this.divideByAttribute = this.clean(x.divideByAttribute, String);
    this.granularity = this.clean(x.granularity, String, WidgetDataGranularities.MONTHLY);
  }

  validate(isNew = false): string[] {
    const e = super.validate();
    if (!isNew && this.iE(this.id)) e.push('id');
    if (this.iE(this.name)) e.push('name');
    if (!Object.values(WidgetTypes).includes(this.type)) e.push('type');
    if (!Object.values(WidgetDataSourceAttributes).includes(this.source)) e.push('source');
    if (!Object.values(WidgetIntervalTypes).includes(this.intervalType)) e.push('intervalType');
    if (this.intervalType === WidgetIntervalTypes.FIXED) {
      if (this.iE(this.since, 'date')) e.push('since');
      if (this.iE(this.to, 'date') || this.since > this.to) e.push('to');
    } else if (this.intervalType === WidgetIntervalTypes.RELATIVE) {
      if (this.relativeSince > this.relativeTo) e.push('to');
    }
    return e;
  }

  /**
   * Whether the widget has enough information to display some data.
   */
  isReadyToDisplay(): boolean {
    const since = this.getSinceDate();
    const to = this.getToDate();
    return this.type && this.source && since && to && since <= to;
  }

  /**
   * Get the starting date, based on the interval type.
   */
  getSinceDate(): epochISODateString {
    if (this.intervalType === WidgetIntervalTypes.FIXED) return this.since;
    if (this.relativeSince === null || this.relativeSince === undefined) return null;
    const d = new Date();
    d.setDate(d.getDate() + this.relativeSince);
    return toISODate(d);
  }
  /**
   * Get the ending date, based on the interval type.
   */
  getToDate(): epochISODateString {
    if (this.intervalType === WidgetIntervalTypes.FIXED) return this.to;
    if (this.relativeTo === null || this.relativeTo === undefined) return null;
    const d = new Date();
    d.setDate(d.getDate() + this.relativeTo);
    return toISODate(d);
  }
}

/**
 * The origin datas sources for the widgets.
 */
export enum WidgetDataSourceOrigin {
  RESOURCES = 'RESOURCES'
}

/**
 * The type of data for the widgets' origin datas sources.
 */
export enum WidgetDataSourceOriginTypes {
  POINT = 'POINT',
  SET = 'SET'
}

/**
 * The attribute datas sources for the widgets.
 * The value represents the data source and the attribute to display.
 */
export enum WidgetDataSourceAttributes {
  RESOURCES_PLAN_STATE = 'RESOURCES:PLAN_STATE:SET',
  RESOURCES_PLAN_SCHEDULE_PROGRESS = 'RESOURCES:PLAN_SCHEDULE_PROGRESS:POINT',
  RESOURCES_PLAN_EXPIRATION = 'RESOURCES:PLAN_EXPIRATION:POINT'
}

/**
 * The widget types available.
 */
export enum WidgetTypes {
  SORTED_LIST = 'sorted-list',
  BAR = 'bar',
  AREA = 'area'
}

/**
 * The sorting mechanisms available for a widget's data.
 */
export enum WidgetDataSortings {
  NONE = 'NONE',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  VALUE_ASC = 'VALUE_ASC',
  VALUE_DESC = 'VALUE_DESC'
}

/**
 * The types date intervals.
 */
export enum WidgetIntervalTypes {
  FIXED = 'FIXED',
  RELATIVE = 'RELATIVE'
}

/**
 * The types of granularities for a widget's data.
 */
export enum WidgetDataGranularities {
  MONTHLY = 'MONTHLY',
  DAILY = 'DAILY'
}

/**
 * The possible type of entities of the platform.
 */
export enum EntityTypes {
  PLANS_STATES = 'PLANS_STATES',
  PLANS_SCHEDULE_PROGRESSES = 'PLANS_SCHEDULE_PROGRESSES',
  PLANS_SEGMENTS = 'PLANS_SEGMENTS',
  CUSTOMERS = 'CUSTOMERS',
  PLANS = 'PLANS',
  COURSES = 'COURSES',
  LESSONS = 'LESSONS'
}

/**
 * An analysis to display in a widget.
 */
export interface Analysis {
  widget: Widget;
  labels: string[];
  datasets: Record<string, { label?: string; data: number[] }>;
}
/**
 * A value for the entity maps used in the analysis.
 */
export class EntityMapValue extends Resource {
  id: string;
  name: string;

  load(x: any): void {
    super.load(x);
    this.id = this.clean(x.id, String);
    this.name = this.clean(x.name, String);
  }
}
