import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Platform, NavController } from '@ionic/angular';
import { IDEAApiService, IDEAStorageService } from '@idea-ionic/common';
import { IDEAAuthService } from '@idea-ionic/auth';

import { AppService } from './app.service';

import { User } from '@models/user.model';

export const authGuard: CanActivateFn = async (): Promise<boolean> => {
  const platform = inject(Platform);
  const navCtrl = inject(NavController);
  const storage = inject(IDEAStorageService);
  const api = inject(IDEAApiService);
  const auth = inject(IDEAAuthService);
  const app = inject(AppService);

  if (app.authReady) return true;

  //
  // HELPERS
  //

  const doAuth = async (): Promise<{ authToken: string; user: User }> => {
    const authRes = await auth.isAuthenticated(false, freshIdToken => (api.authToken = freshIdToken));
    api.authToken = authRes.idToken;
    app.user = new User(authRes.userDetails);

    return { authToken: api.authToken.toString(), user: app.user };
  };

  const navigateAndResolve = (navigationPath?: string[]): boolean => {
    if (navigationPath) navCtrl.navigateRoot(navigationPath);
    app.authReady = true;
    return true;
  };

  //
  // MAIN
  //

  await platform.ready();
  await storage.ready();

  try {
    await doAuth();
    platform.resume.subscribe((): Promise<{ authToken: string; user: User }> => doAuth());

    if (window.location.pathname === '/') return navigateAndResolve([]);
    return navigateAndResolve();
  } catch (err) {
    return navigateAndResolve(['auth']);
  }
};
