/**
 * Variables to configure an ITER IDEA's cloud app, together with its inner modules.
 */
export const environment = {
  idea: {
    project: 'tip',
    ionicExtraModules: ['common', 'auth', 'variables'],
    app: {
      version: '3.5.3',
      url: 'https://tip-wtraining.it',
      bundle: 'com.iteridea.tip',
      mediaUrl: 'https://media.tip-wtraining.it'
    },
    api: {
      url: 'api.tip-wtraining.it',
      stage: 'dev'
    },
    auth: {
      title: 'Gestione progetti formativi',
      website: 'https://iter-idea.com',
      hasIntroPage: false,
      registrationIsPossible: false,
      singleSimultaneousSession: false,
      forceLoginWithMFA: false,
      passwordPolicy: {
        minLength: 8,
        requireLowercase: false,
        requireDigits: false,
        requireSymbols: false,
        requireUppercase: false
      }
    }
  },
  aws: {
    cognito: {
      userPoolId: 'eu-west-1_dekqPtglU',
      userPoolClientId: '6upd843jcnf2rani16t7ickirp'
    }
  }
};
