import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { IDEAStorageService, IDEATranslationsService } from '@idea-ionic/common';

import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  menuPages: MenuPage[] = [
    {
      title: 'MENU.TASKS',
      navigationPath: ['tasks'],
      icon: 'clipboard',
      hasHeader: 'MENU.MENU',
      visibleOn: 'isUserManager'
    },
    { title: 'MENU.PLANS', navigationPath: ['plans'], icon: 'book' },
    { title: 'MENU.CUSTOMERS', navigationPath: ['customers'], icon: 'people', visibleOn: 'isUserManager' },
    { title: 'MENU.TEACHERS', navigationPath: ['teachers'], icon: 'school', visibleOn: 'isUserManager' },
    { title: 'MENU.ARCHIVE', navigationPath: ['archive'], icon: 'archive' },
    { title: 'MENU.WIDGETS', navigationPath: ['widgets'], icon: 'bar-chart' },
    { title: 'MENU.INFO', method: 'info', icon: 'information-circle', hasHeader: 'MENU.ACTIONS' },
    { title: 'MENU.LOGOUT', method: 'logout', icon: 'log-out' }
  ];

  /**
   * Whether the sidebar is in mini or normal mode.
   */
  private miniMode: boolean;
  /**
   * The storage key for the sidebar miniMode preference.
   */
  private MINI_MODE_STORAGE_KEY = 'sidebarMiniMode';

  constructor(
    private menuCtrl: MenuController,
    private router: Router,
    private storage: IDEAStorageService,
    public t: IDEATranslationsService,
    public app: AppService
  ) {}
  async ngOnInit(): Promise<void> {
    await this.storage.ready();
    this.miniMode = Boolean(await this.storage.get(this.MINI_MODE_STORAGE_KEY));
  }

  navigateToPath(path: string[]): void {
    this.app.goTo(path, { root: true });
    this.menuCtrl.close();
  }
  isPageVisible(p: MenuPage): boolean {
    return !p.visibleOn || (this as any)[p.visibleOn]();
  }
  runMenuMethod(method: string): void {
    if ((this as any)[method]) (this as any)[method]();
  }

  info(): void {
    this.app.info();
  }
  logout(): void {
    this.app.logout();
  }
  openProfile(): void {
    this.navigateToPath(['profile']);
  }

  isUserManager(): boolean {
    return this.app.user?.isManager();
  }

  shouldHideSplitPane(): boolean {
    return !this.app.user;
  }
  isMiniMode(): boolean {
    return !this.app.isMobile() && this.miniMode;
  }
  async toggleMiniMode(): Promise<void> {
    await this.storage.set(this.MINI_MODE_STORAGE_KEY, !this.miniMode);
    this.miniMode = !this.miniMode;
  }

  isPageWithoutMenu(): boolean {
    return !this.router.url || ['echo', 'auth'].some(x => this.router.url.startsWith('/' + x));
  }
}

interface MenuPage {
  /**
   * The title of the button; a title_HINT will be automatically added as tooltip.
   */
  title?: string;
  /**
   * The url to navigate to on click.
   */
  navigationPath?: string[];
  /**
   * The name of the component method to run on click.
   */
  method?: string;
  /**
   * ion-icon code.
   */
  icon: string;
  /**
   * If set, prepone a header before the item.
   */
  hasHeader?: string;
  /**
   * If set, represents the name of the component method to check if the action can be seen.
   */
  visibleOn?: string;
}
