import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { initGuard } from './init.guard';
import { authGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'customers', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: (): Promise<any> => import('@idea-ionic/auth').then(m => m.IDEAAuthModule),
    canActivate: [initGuard]
  },
  {
    path: 'echo',
    loadChildren: (): Promise<any> => import('@idea-ionic/common').then(m => m.IDEAEchoModule),
    canActivate: [initGuard]
  },
  {
    path: 'profile',
    loadChildren: (): Promise<any> => import('./users/profile.module').then(m => m.ProfileModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'customers',
    loadChildren: (): Promise<any> => import('./customers/customers.module').then(m => m.CustomersModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teachers',
    loadChildren: (): Promise<any> => import('./teachers/teachers.module').then(m => m.TeachersModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'plans',
    loadChildren: (): Promise<any> => import('./plans/plans.module').then(m => m.PlansModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'archive',
    loadChildren: (): Promise<any> => import('./archive/archive.module').then(m => m.ArchiveModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'widgets',
    loadChildren: (): Promise<any> => import('./widgets/widgets.module').then(m => m.WidgetsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'tasks',
    loadChildren: (): Promise<any> => import('./tasks/tasks.module').then(m => m.TasksModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'roll-calls',
    loadChildren: (): Promise<any> => import('./rollCalls/rollCalls.module').then(m => m.RollCallsModule),
    canActivate: [initGuard, authGuard]
  },
  {
    path: 'teachers/:teacherId/roll-calls',
    loadChildren: (): Promise<any> => import('./rollCalls/rollCalls.module').then(m => m.RollCallsModule),
    canActivate: [initGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
