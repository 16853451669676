import { Injectable } from '@angular/core';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { Params } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { IDEAMessageService, IDEAStorageService, IDEATranslationsService } from '@idea-ionic/common';
import { IDEAAuthService } from '@idea-ionic/auth';

import { environment as env } from '@env';

import { User } from '@models/user.model';
import { EntityTypes } from '@models/widget.model';

/**
 * The base URLs where the thumbnails are located.
 */
const THUMBNAILS_BASE_URL = env.idea.app.mediaUrl.concat('/images/', env.idea.api.stage, '/');
/**
 * A local fallback URL for the users avatars.
 */
const AVATAR_FALLBACK_URL = './assets/imgs/no-avatar.jpg';

@Injectable({ providedIn: 'root' })
export class AppService {
  initReady = false;
  authReady = false;

  private darkMode: boolean;

  user: User;

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private message: IDEAMessageService,
    private storage: IDEAStorageService,
    private auth: IDEAAuthService,
    private t: IDEATranslationsService
  ) {
    this.darkMode = this.respondToColorSchemePreferenceChanges();
  }
  private respondToColorSchemePreferenceChanges(): boolean {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => (this.darkMode = e.matches));
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  /**
   * Whether we should display a UX designed for smaller screens.
   */
  isInMobileMode(): boolean {
    return this.platform.width() < 768;
  }
  /**
   * Whether we can hide the plan info based on the screen width.
   */
  canHidePlanInfo(): boolean {
    return this.platform.width() >= 992;
  }
  /**
   * Whether we should display a UX designed for smaller screens.
   */
  isInDarkMode(): boolean {
    return this.darkMode;
  }

  /**
   * Reload the app.
   */
  reloadApp(): void {
    window.location.assign('');
  }
  /**
   * Navigate to a page by its path.
   */
  goTo(path: string[], options: { back?: boolean; root?: boolean; queryParams?: Params } = {}): void {
    if (options.back) this.navCtrl.navigateBack(path, options);
    else if (options.root) this.navCtrl.navigateRoot(path, options);
    else this.navCtrl.navigateForward(path, options);
  }
  /**
   * Close the current page and navigate back, optionally displaying an error message.
   */
  closePage(errorMessage?: string, pathBack?: string[]): void {
    if (errorMessage) this.message.error(errorMessage);
    try {
      this.navCtrl.back();
    } catch (_) {
      this.goTo(pathBack || [], { back: true });
    }
  }

  /**
   * Whether we should show the mobile UI.
   */
  isMobile(): boolean {
    return this.platform.width() < 768;
  }

  /**
   * Get the URL to an image by its URI.
   */
  private getImageURLByURI(imageURI: string): string {
    return THUMBNAILS_BASE_URL.concat(imageURI, '.png');
  }
  /**
   * Get the URL to a user's profile image (avatar).
   */
  getUserImageURL(user: User): string {
    return user?.picture ? this.getImageURLByURI(user.picture) : AVATAR_FALLBACK_URL;
  }
  /**
   * Load a fallback URL when the user's avatar is missing.
   */
  fallbackUserAvatar(targetImg: any): void {
    if (targetImg && targetImg.src !== AVATAR_FALLBACK_URL) targetImg.src = AVATAR_FALLBACK_URL;
  }

  /**
   * Calculate the difference in days between to days.
   */
  dayDiff(dt1: Date, dt2: Date = new Date()): number {
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  }

  /**
   * Show some app's info.
   */
  async info(): Promise<void> {
    const openPrivacyPolicy = () => Browser.open({ url: this.t._('IDEA_VARIABLES.PRIVACY_POLICY_URL') });

    const header = this.t._('COMMON.APP_NAME');
    const message = this.t._('COMMON.VERSION', { v: env.idea.app.version });
    const buttons = [
      { text: this.t._('IDEA_AUTH.PRIVACY_POLICY'), handler: openPrivacyPolicy },
      { text: this.t._('COMMON.CLOSE') }
    ];

    const alert = await this.alertCtrl.create({ header, message, buttons });
    alert.present();
  }

  /**
   * Sign-out from the current user.
   */
  async logout(): Promise<void> {
    const doLogout = () => this.auth.logout().finally(() => this.storage.clear().then(() => this.reloadApp()));

    const header = this.t._('COMMON.LOGOUT');
    const message = this.t._('COMMON.ARE_YOU_SURE');
    const buttons = [{ text: this.t._('COMMON.CANCEL') }, { text: this.t._('COMMON.LOGOUT'), handler: doLogout }];

    const alert = await this.alertCtrl.create({ header, message, buttons });
    alert.present();
  }

  /**
   * Utility to generate a numeric array.
   * Useful for skeleton interfaces.
   */
  generateNumericArray(length: number): number[] {
    return [...Array(length).keys()];
  }

  /**
   * Utility to convert a number in the `{hours}h {minutes}m` format.
   */
  convertDurationInString(num: number): string {
    if (!num) return '0h';
    const [int, dec] = num.toString().split('.');
    if (!dec) return `${int}h`;
    else return `${int}h ${dec.length === 1 ? Number(dec) * 6 : Number(dec) * 0.6}m`;
  }

  /**
   * Returns the icon for a specific entity
   */
  getIconForEntity(entityType: EntityTypes, outline = false): string {
    return EntityTypesIcons[entityType].concat(outline ? '-outline' : '');
  }
}

/**
 * The breacrumbs to show in a page.
 */
export type Breadcrumbs = Breadcrumb[];

/**
 * The standard used to display the breadcrumb in the app's pages.
 */
export interface Breadcrumb {
  /**
   * The key (to translate) for displaying the breadcrumb title.
   */
  titleKey: string;
  /**
   * The navigation path that leads to the page referred by the breadcrumb.
   */
  path?: string[];
}

export enum EntityTypesIcons {
  CUSTOMERS = 'people',
  PLANS = 'people',
  PLANS_STATES = 'bar-chart',
  PLANS_SCHEDULE_PROGRESSES = 'analytics',
  PLANS_SEGMENTS = 'calendar'
}
