import { CognitoUser, Resource } from 'idea-toolbox';
import { Widget } from './widget.model';

export class User extends CognitoUser {
  /**
   * Whether the user is part of the managers group.
   * If a user is an Admin, it is also a Manager.
   */
  isManager(): boolean {
    return this.groups.includes('managers') || this.isAdmin();
  }
  /**
   * The IDs of the customers linked to the user.
   */
  getCustomersIds(): string[] {
    return this.groups.filter(g => !['admins', 'managers'].includes(g));
  }
}

export class UserAttached extends Resource {
  userId: string;
  name: string;

  load(x: any): void {
    super.load(x);
    this.userId = this.clean(x.userId, String);
    this.name = this.clean(x.name, String);
  }
}

export class UserWithWidget extends Resource {
  /**
   * The ID of the user.
   */
  userId: string;
  /**
   * The ID of the widget.
   */
  widgetId: number;
  /**
   * An index to position the widget in the correct place.
   */
  index: number;
  /**
   * The widget of the user.
   */
  widget: Widget;

  load(x: any): void {
    super.load(x);
    this.userId = this.clean(x.userId, String);
    this.widgetId = this.clean(x.widgetId, Number);
    this.index = this.clean(x.index, Number);
    this.widget = this.clean(x.widget, w => new Widget(w));
  }
}
